import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TurbineApiService } from '@/core/api.service';
import { Domain } from '@/domains/domain';
import { EntityVersion } from '@/shared-ui/versions/versions';

import { Team, VersionedTeam } from './team';

@Injectable({ providedIn: 'root' })
export class TeamService {
  constructor(private api: TurbineApiService) {}

  getTeams(allTeams: boolean = false): Observable<Team[]> {
    return this.api.getJson(
      `${this.api.urls.auth}/teams?allTeams=${allTeams}`,
      [],
    );
  }

  getTeamsByDomain(
    domain: string,
    below: boolean = false,
    above: boolean = false,
  ): Observable<Team[]> {
    return this.api
      .getJson(
        `${this.api.urls.auth}/domains/${encodeURIComponent(
          domain,
        )}/teams?below=${below}&above=${above}`,
        [],
      )
      .pipe(map((teams) => this.sort(teams)));
  }

  getTeamDomains(name: string): Observable<Domain[]> {
    return this.api.getJson(`${this.api.urls.auth}/teams/${name}/domains`, []);
  }

  getTeamsByUser(id: string): Observable<Team[]> {
    return this.api
      .getJson(`${this.api.urls.auth}/users/${id}/teams`, [])
      .pipe(map((teams) => this.sort(teams)));
  }

  getTeam(name: string): Observable<Team> {
    return this.api.getJson(`${this.api.urls.auth}/teams/${name}`);
  }

  updateTeam(team: Team): Observable<Team> {
    return this.api.putJson(`${this.api.urls.auth}/teams/${team.name}`, team);
  }

  regenerateTeamToken(
    teamName: string,
    tokenName: string,
    tokenDescription: string,
  ): Observable<Team> {
    return this.api.postJson(`${this.api.urls.auth}/teams/${teamName}/tokens`, {
      name: tokenName,
      description: tokenDescription,
    });
  }

  updateTeamTokenExpiration(
    teamName: string,
    tokenName: string,
    expiration: Date,
  ): Observable<Team> {
    const exp = new Date(expiration).toISOString();
    const token = {
      name: tokenName,
      expiration: exp,
    };
    return this.api.putJson(
      `${this.api.urls.auth}/teams/${teamName}/tokens`,
      token,
      { etag: false },
    );
  }

  updateTeamTokenDescription(
    teamName: string,
    tokenName: string,
    description: string,
  ): Observable<Team> {
    return this.api.patchJson(
      `${this.api.urls.auth}/teams/${teamName}/tokens/${tokenName}`,
      {
        patches: [
          {
            op: 'replace',
            path: 'description',
            value: description,
          },
        ],
      },
    );
  }

  deleteTeamToken(teamName: string, tokenName: string): Observable<Team> {
    return this.api.delete(
      `${this.api.urls.auth}/teams/${teamName}/tokens/${tokenName}`,
    );
  }

  createTeam(team: Team): Observable<Team> {
    return this.api.postJson(`${this.api.urls.auth}/teams`, team);
  }

  getTeamVersions(name: string): Observable<EntityVersion[]> {
    return this.api.getJson(
      `${this.api.urls.auth}/teams/${name}/_versions`,
      [],
    );
  }

  getTeamVersion(name: string, version: number): Observable<VersionedTeam> {
    return this.api.getJson(
      `${this.api.urls.auth}/teams/${name}/_versions/${version}`,
      [],
    );
  }

  deleteTeamVersion(name: string, version: number): Observable<VersionedTeam> {
    return this.api.delete(
      `${this.api.urls.auth}/teams/${name}/_versions/${version}`,
    );
  }

  deleteTeam(name: string): Observable<void> {
    return this.api.delete(`${this.api.urls.auth}/teams/${name}`);
  }

  sort(teams: Team[]): Team[] {
    return teams.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }
}
