/* Copyright Coorganix SAS */
/* eslint-disable @typescript-eslint/member-ordering */

type TCustomStyle =
  | 'default'
  | 'warning'
  | 'info'
  | 'danger'
  | 'primary'
  | 'success';

export class Alert {
  static warn10s(message: string): Alert {
    return new Alert(message, 'warning', 10000);
  }

  static warn5s(message: string): Alert {
    return new Alert(message, 'warning', 5000);
  }

  static error10s(message: string): Alert {
    return new Alert(message, 'danger', 10000);
  }

  static ok1s(message: string): Alert {
    return new Alert(message, 'success', 1000);
  }

  static ok3s(message: string): Alert {
    return new Alert(message, 'success', 3000);
  }

  static custom(
    message: string,
    style: TCustomStyle = 'info',
    duration: number = 5000,
  ): Alert {
    return new Alert(message, style, duration);
  }

  private readonly _message: string;
  private readonly _customStyle: TCustomStyle;
  private readonly _time: number;

  constructor(message: string, customStyle: TCustomStyle, time: number) {
    this._message = message;
    this._customStyle = customStyle;
    this._time = time;
  }

  get message(): string {
    return this._message;
  }

  get customStyle() {
    return this._customStyle;
  }

  get time(): number {
    return this._time;
  }
}
