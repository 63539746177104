<div class="dropdown">
  <button
    id="favorite-dropdown"
    class="btn btn-warning text-white d-flex justify-content-center align-items-center mr-2 favorite__button"
    type="button"
    data-toggle="dropdown"
    aria-expanded="false"
  >
    <i class="fa fa-star" aria-hidden="true"></i>
  </button>

  <div id="favorite-dropdown-menu" class="dropdown-menu dropdown-menu-right">
    @for (ressource of ressources; track ressource.name; let i = $index) {
      <h6 class="dropdown-header">
        <i class="{{ ressource.icon }} mr-2" aria-hidden="true"></i>
        <span class="text-capitalize">{{ ressource.name }}</span>
      </h6>
      @for (favorite of ressource.favorites; track favorite.name) {
        <div class="dropdown-item d-flex align-items-center">
          <a class="dropdown-item p-0" routerLink="{{ favorite.link }}">{{ favorite.name }}</a>
          <i class="fa fa-times cursor-pointer ml-4" (click)="removeFavorite(ressource.name, favorite.name)" aria-hidden="true"></i>
        </div>
      }
      @if (i < ressources.length - 1) {
        <div class="dropdown-divider"></div>
      }
    } @empty {
      <p class="text-center mb-0">No favorites</p>
    }
  </div>
</div>
