import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { TurbineApiService } from './api.service';
import { ConfigurationService } from './configuration.service';

type NotificationChannel = 'MAIL' | 'SLACK' | 'SERVICE_NOW' | 'ALL';

export interface NotificationBody {
  recipients: string[];
  template_vars: Record<string, unknown>;
}

interface Feedback {
  user: string;
  message: string;
}

export interface NotificationPreferences {
  type: string;
  name: string;
  channel: string;
  enabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private api = inject(TurbineApiService);
  private configurationService = inject(ConfigurationService);

  sendMessage(
    messageId: string,
    channel: NotificationChannel,
    notificationBody: NotificationBody,
  ): Observable<boolean> {
    return this.api.postJson(
      `${this.api.urls.notification}/messages/${messageId}/${channel}`,
      notificationBody,
    );
  }

  sendWelcomeMail(notificationBody: NotificationBody): Observable<boolean> {
    let notificationWelcomeId = '';
    this.configurationService.getConfiguration().subscribe((conf) => {
      notificationWelcomeId = conf.turbine.notificationWelcomeId;
    });

    return this.sendMessage(
      notificationWelcomeId,
      'MAIL',
      notificationBody,
    ).pipe(
      map(() => {
        this.configurationService.setStorageItem(
          'welcome-email-sent',
          'true',
          90,
        );
        return true;
      }),
      catchError((error) => {
        if (error && error.status === 401) {
          return of(false);
        } else {
          return of(null);
        }
      }),
    );
  }

  sendFeedback(feedback: Feedback): Observable<boolean> {
    return this.api.postJson(
      `${this.api.urls.bff}/notification/feedback`,
      feedback,
      { authoring: false },
    );
  }

  getNotificationPreferences(
    resource: string,
    id: string,
  ): Observable<NotificationPreferences> {
    return this.api.getJson(
      `${this.api.urls.notification}/preferences/${resource}/${id}`,
      null,
      true,
    );
  }

  createNotificationPreferences(
    resource: string,
    id: string,
    data: NotificationPreferences,
  ): Observable<boolean> {
    return this.api.postJson(
      `${this.api.urls.notification}/preferences/${resource}/${id}`,
      data,
    );
  }

  updateNotificationPreferences(
    resource: string,
    id: string,
    data: NotificationPreferences,
  ): Observable<boolean> {
    return this.api.putJson(
      `${this.api.urls.notification}/preferences/${resource}/${id}`,
      data,
    );
  }
}
