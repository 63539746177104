import { Component, EventEmitter, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { Alert } from '@/core/dialog/dialog';
import { DialogService } from '@/core/dialog/dialog.service';
import type { ModalContent } from '@/core/modal/modal';
import { NotificationService } from '@/core/notification.service';
import { ShowErrorsComponent } from '@/core/show-errors.component';
import { UserService } from '@/users/user.service';

@Component({
  standalone: true,
  selector: 'turbine-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  imports: [ReactiveFormsModule, ShowErrorsComponent],
})
export class FeedbackModalComponent implements ModalContent {
  onComponentUpdated = new EventEmitter<void>();

  feedbackControl = new FormControl<string>('', Validators.required);
  isSubmited: boolean = false;

  private notificationService = inject(NotificationService);
  private userService = inject(UserService);
  private dialogService = inject(DialogService);

  modalChildAction(): void {
    this.isSubmited = true;
    if (this.feedbackControl.invalid) return;

    this.notificationService
      .sendFeedback({
        user: this.userService.me.name,
        message: this.feedbackControl.value,
      })
      .subscribe(() => {
        this.dialogService.alert(
          Alert.ok3s('Your feedback has been sent to the team.'),
        );
        this.onComponentUpdated.emit();
      });
  }
}
