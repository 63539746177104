import { Directive, ElementRef, Input } from '@angular/core';

import { ACL } from './user';
import { UserService } from './user.service';

@Directive({
  selector: '[turbineACL]',
  standalone: true,
})
export class AclDirective {
  private readonly _display: string;

  private _acl: ACL[];

  constructor(
    private el: ElementRef,
    private userService: UserService,
  ) {
    this._display = this.el.nativeElement.style.display;
    this.compute();
  }

  @Input('turbineACL')
  set acl(acl: ACL | ACL[]) {
    if (Array.isArray(acl)) {
      this._acl = acl;
    } else if (acl) {
      this._acl = [acl];
    }

    this.compute();
  }

  hide() {
    this.el.nativeElement.style.setProperty('display', 'none', 'important');
    this.el.nativeElement.setAttribute('has-acl', false);
  }

  show() {
    this.el.nativeElement.style.display = this._display;
    this.el.nativeElement.setAttribute('has-acl', true);
  }

  compute(): void {
    if (!this.userService.me) {
      this.hide();
      return;
    }

    if (this.userService.hasOneACL(this._acl)) {
      this.show();
    } else {
      this.hide();
    }
  }
}
