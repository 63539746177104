<nav class="mt-5" [class.active]="isCollapsed" id="sidebar" role="navigation" title="Sidebar navigation">
  <ul class="list-unstyled components">
    <li routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Dashboard">
      <a
        routerLink="/users/me"
        title="Dashboard"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Dashboard',
          })
        "
      >
        <i class="fas fa-home" aria-hidden="true"></i> <span>Dashboard</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'environment', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Environments">
      <a
        routerLink="/environments"
        title="Environments"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Environments',
          })
        "
      >
        <i class="fas fa-boxes" aria-hidden="true"></i> <span>Environments</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'component', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Components">
      <a
        routerLink="/components"
        title="Components"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Components',
          })
        "
      >
        <i class="fas fa-box" aria-hidden="true"></i> <span>Components</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'template', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Templates">
      <a
        routerLink="/templates"
        title="Templates"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Templates',
          })
        "
      >
        <i class="fas fa-file-code" aria-hidden="true"></i> <span>Templates</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'federation', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Federations">
      <a
        routerLink="/federations"
        title="Federations"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Federations',
          })
        "
      >
        <i class="icon-federation" [class.mr-2]="!isCollapsed" aria-hidden="true"></i> <span>Federations</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'platform', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Platforms">
      <a
        routerLink="/platforms"
        title="Platforms"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Platforms',
          })
        "
      >
        <i class="fas fa-cloud" aria-hidden="true"></i> <span>Platforms</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'team', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Teams">
      <a
        routerLink="/teams"
        title="Teams"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Teams',
          })
        "
      >
        <i class="fas fa-users" aria-hidden="true"></i> <span>Teams</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'acl', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="ACL">
      <a
        routerLink="/acl"
        title="ACL"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'ACL',
          })
        "
      >
        <i class="fas fa-user-tag" aria-hidden="true"></i> <span>ACL</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'domain', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Domains">
      <a
        routerLink="/domains"
        title="Domains"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Domains',
          })
        "
      >
        <i class="fas fa-sitemap" aria-hidden="true"></i> <span>Domains</span>
      </a>
    </li>
    <li [turbineACL]="{ resource: 'report', verbs: ['read'] }">
      <a
        href="#configSubmenu"
        data-toggle="collapse"
        (click)="collapseHandler()"
        aria-expanded="false"
        aria-label="Open reports submenu"
        class="dropdown-toggle"
      >
        <i class="fas fa-chart-pie" aria-hidden="true"></i> <span>Reports</span>
      </a>
      <ul class="collapse list-unstyled" id="configSubmenu" [class.collapse]="configSubmenuCollapsed">
        <li [turbineACL]="{ resource: 'report', verbs: ['read'] }" routerLinkActive="active" title="Billing Idling">
          <a
            routerLink="/reports/idling"
            title="Idling Reports"
            (click)="
              trackingService.gtmSend({
                event: 'gaEvent',
                type: 'root_view',
                category: 'App',
                action: 'click',
                label: 'Sidebar',
                value: 'Reports :: Idling',
              })
            "
          >
            <i class="fas fa-file-invoice-dollar" aria-hidden="true"></i> <span>Idling</span>
          </a>
        </li>
        <li [turbineACL]="{ resource: 'report', verbs: ['read'] }" routerLinkActive="active" title="Versions Reports">
          <a
            routerLink="/reports/versions"
            title="Versions Reports"
            (click)="
              trackingService.gtmSend({
                event: 'gaEvent',
                type: 'root_view',
                category: 'App',
                action: 'click',
                label: 'Sidebar',
                value: 'Reports :: Versions',
              })
            "
          >
            <i class="fas fa-table" aria-hidden="true"></i> <span>Versions</span>
          </a>
        </li>
      </ul>
    </li>
    <li [turbineACL]="{ resource: 'environment', verbs: ['read'] }" routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Activity">
      <a
        routerLink="/jobs"
        title="Activity"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Activity',
          })
        "
      >
        <i class="fas fa-clipboard-list" aria-hidden="true"></i> <span>Activity</span>
      </a>
    </li>
  </ul>
  <hr />
  <ul class="list-unstyled get-help">
    <li data-toggle="tooltip" data-placement="left" title="Send feedback">
      <a
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Feedback',
          });
          openFeedbackModal()
        "
        title="Send feedback"
      >
        <i class="far fa-comment" aria-hidden="true"></i> <span>Send feedback</span>
      </a>
    </li>
    <li data-toggle="tooltip" data-placement="left" title="#delivery-run on Slack">
      <a
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Slack',
          })
        "
        target="_blank"
        rel="noopener"
        title="Get support on Slack #delivery-run"
        href="https://adeo-tech-community.slack.com/archives/CCKQPKA6Q"
      >
        <i class="fab fa-slack" aria-hidden="true"></i> <span>#delivery-run</span>
      </a>
    </li>
    <li routerLinkActive="active" data-toggle="tooltip" data-placement="left" title="Support">
      <a
        routerLink="/support"
        title="Support"
        (click)="
          trackingService.gtmSend({
            event: 'gaEvent',
            type: 'root_view',
            category: 'App',
            action: 'click',
            label: 'Sidebar',
            value: 'Support',
          })
        "
      >
        <i class="far fa-question-circle" aria-hidden="true"></i> <span>Support</span>
      </a>
    </li>
  </ul>
</nav>
